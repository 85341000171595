import { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import Logo from "./Logo.svg";
import { Ripple } from "./components/magicui/ripple/ripple.tsx";

function App() {
  const [showSplash, setShowSplash] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [answers, setAnswers] = useState({
    name: "",
    email: "",
    lookingForDev: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  const emailEndpoint = "https://devbrother.com/api/send-landing-email";

  const handleSplashClick = () => {
    const splashScreen = document.querySelector(".splash-screen");
    if (!splashScreen) return;
    splashScreen.classList.add("fade-out");

    setTimeout(() => {
      setShowSplash(false);
    }, 1000);
  };

  const validateInput = (value, step) => {
    setError("");

    switch (step) {
      case 0: // name
        if (!value.trim()) {
          setError("Please enter your name");
          return false;
        }
        if (value.length < 2) {
          setError("Minimum 2 letters required");
          setInputValue("");
          document.querySelector("input").placeholder =
            "Minimum 2 letters required";
          return false;
        }
        if (!/^[a-zA-Zа-яА-Я\s]{2,}$/.test(value)) {
          setError("Name can only contain letters");
          setInputValue("");
          document.querySelector("input").placeholder =
            "Name can only contain letters";
          return false;
        }
        break;

      case 1: // email
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          setError("Please enter a valid email");
          setInputValue("");
          document.querySelector("input").placeholder =
            "Please enter a valid email";
          return false;
        }
        break;

      case 2: // yes/no
        if (!["yes", "no"].includes(value.toLowerCase())) {
          setError("Please enter yes or no");
          setInputValue("");
          document.querySelector("input").placeholder =
            "Please enter yes or no";
          return false;
        }
        break;
    }
    return true;
  };

  const handleRadioChange = (value) => {
    setInputValue(value);
  };

  const handleSubmit = async () => {
    if (!validateInput(inputValue, currentStep)) {
      return;
    }

    const currentQuestion = questions[currentStep];
    const updatedAnswers = {
      ...answers,
      [currentQuestion.key]: inputValue,
    };

    setAnswers(updatedAnswers);
    
    if (currentStep === questions.length - 1) {
      try {
        // console.log("Submitting answers:", updatedAnswers);
        const response = await fetch(emailEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: updatedAnswers.name.trim(),
            email: updatedAnswers.email.trim(),
            lookingForDev: updatedAnswers.lookingForDev.trim(),
          }),
        });

        if (!response.ok) {
          const error = await response.json();
          setError(`Failed to send email: ${error?.error || "Unknown error"}`);
          return;
        }

        // Show success message
        setShowSuccessMessage(true);

        setTimeout(() => {
          const formScreen = document.querySelector(".form-screen");
          formScreen.classList.add("fade-out");

          setTimeout(() => {
            setShowSuccessMessage(false);
            setCurrentStep(0);
            setInputValue("");
            setAnswers({
              name: "",
              email: "",
              lookingForDev: "",
            });
            setShowSplash(true);
          }, 500);
        }, 6000);
      } catch (error) {
        console.error("Error sending:", error);
        setError("Failed to send message. Please try again.");
      }
    } else {
      setCurrentStep((prev) => prev + 1);
      setInputValue("");
    }
  };

  const questions = [
    { key: "name", text: "How should I address you?" },
    { key: "email", text: "What is your email?" },
    {
      key: "lookingForDev",
      text: "Are there any development needs you anticipate in the future?",
    },
  ];

  const createParticles = () => {
    // Create particles for visual effect
    const particles = [];
    for (let i = 0; i < 100; i++) {
      particles.push(
        <div
          key={i}
          className="particle"
          style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            animation: `float ${5 + Math.random() * 10}s linear infinite`,
          }}
        />
      );
    }
    return particles;
  };

  useEffect(() => {
    const splashScreen = document.querySelector(".splash-screen");
    if (!splashScreen) return;

    const handleKeyPress = () => {
      handleSplashClick();
    };

    window.addEventListener("keydown", handleKeyPress);

    const handleAnimationEnd = () => {
      setIsAnimationComplete(true);
    };

    splashScreen.addEventListener("animationend", handleAnimationEnd);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      splashScreen.removeEventListener("animationend", handleAnimationEnd);
    };
  }, []);

  useEffect(() => {
    if (currentStep === 2 && (inputValue === "yes" || inputValue === "no")) {
      handleSubmit();
    }
  }, [inputValue, currentStep]);

  return (
    <div>
      <div className="particles">{createParticles()}</div>

      {showSplash ? (
        <div className="splash-screen" onClick={handleSplashClick}>
          <div className="logo">
            <Ripple />
            <img src={Logo} alt="DevBrother" className="logo-svg" />
          </div>
          <div className="logo-text">DevBrother</div>
          <div className="tagline">
            {/* {isAnimationComplete && ( */}
              <Typewriter
                options={{
                  strings: [
                    "Are you planning to develop an idea in the future?",
                  ],
                  autoStart: true,
                  delay: 40,
                  cursor: "",
                  loop: false,
                  deleteSpeed: Infinity,
                  startDelay: 0,
                }}
              />
            {/* )} */}
          </div>
        </div>
      ) : (
        <div className="form-screen">
          {showSuccessMessage ? (
            <div className="success-message">
              <Typewriter
                options={{
                  strings: [
                    "Your message has been transmitted successfully, we will be in touch.",
                  ],
                  autoStart: true,
                  delay: 40,
                  cursor: "",
                  loop: false,
                  deleteSpeed: Infinity,
                }}
              />
            </div>
          ) : (
            <>
              <div className="question">
                <Typewriter
                  options={{
                    strings: [questions[currentStep].text],
                    autoStart: true,
                    delay: 40,
                    cursor: "",
                    loop: false,
                    deleteSpeed: Infinity,
                  }}
                />
              </div>
              <div className="input-container">
                {currentStep === 2 ? (
                  <div className="radio-container">
                    <label className="custom-radio">
                      <input
                        type="radio"
                        value="yes"
                        checked={inputValue === "yes"}
                        onChange={() => {
                          handleRadioChange("yes");
                        }}
                      />
                      <div className="radio-button">
                        <span className="pulse-circle"></span>
                      </div>
                      Yes
                    </label>
                    <label className="custom-radio">
                      <input
                        type="radio"
                        value="no"
                        checked={inputValue === "no"}
                        onChange={() => {
                          handleRadioChange("no");
                        }}
                      />
                      <div className="radio-button">
                        <span className="pulse-circle"></span>
                      </div>
                      No
                    </label>
                  </div>
                ) : (
                  <>
                    {currentStep === 1 ? (
                      <input
                        type="email"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                        placeholder={error || "Enter your email"}
                        autoFocus
                        className={error ? "error" : ""}
                      />
                    ) : (
                      <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                        placeholder={
                          error
                            ? error
                            : currentStep === 0
                            ? "Enter your name"
                            : "How did you find us"
                        }
                        autoFocus
                        className={error ? "error" : ""}
                      />
                    )}
                    <button onClick={handleSubmit}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="white"
                      >
                        <path d="M16 8v-4l8 8-8 8v-4h-5v-8h5zm-7 0h-2v8h2v-8zm-4.014 0h-1.986v8h1.986v-8zm-3.986 0h-1v8h1v-8z" />
                      </svg>
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
