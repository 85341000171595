import React, { ComponentPropsWithoutRef, CSSProperties } from "react";
import { cn } from "../../../lib/utils";

interface RippleProps extends ComponentPropsWithoutRef<"div"> {
  mainCircleSize?: number;
  mainCircleOpacity?: number;
  numCircles?: number;
}

export const Ripple = React.memo(function Ripple({
  mainCircleSize = 150,
  mainCircleOpacity = 0.25,
  numCircles = 10,
  className,
  ...props
}: RippleProps) {
  return (
    <div
      className={cn(
        "pointer-events-none absolute inset-0 select-none",
        // "pointer-events-none absolute inset-0 select-none [mask-image:linear-gradient(to_bottom,white,transparent)]",
        className,
      )}
      {...props}
    >
      {Array.from({ length: numCircles }, (_, i) => {
        const size = mainCircleSize + i * 80;
        const opacity = mainCircleOpacity - i * 0.025;
        const animationDelay = `${i * 0.08}s`;
        const borderStyle = i === numCircles - 1 ? "dashed" : "solid";
        const borderOpacity = 5 + i * 5;

        const grayScale = Math.floor(255 - (i * (255 / (numCircles - 1))));

        return (
          <div
            key={i}
            className={`absolute animate-ripple rounded-full border shadow-xl`}
            style={{
              width: `${size}px`,
              height: `${size}px`,
              opacity,
              animationDelay,
              borderStyle,
              borderWidth: "1px",
              background: `rgb(${grayScale}, ${grayScale}, ${grayScale})`,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) scale(1)",
              // borderColor: `rgb(${grayScale}, ${grayScale}, ${grayScale})`,
            } as CSSProperties}
          />
        );
      })}
    </div>
  );
});

Ripple.displayName = "Ripple";